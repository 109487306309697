import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"

const GuidelinesPage = () => (
  <Layout>
    <SEO title="Author Guidelines" />
    <Banner title="Author Guidelines"></Banner>

    <section id="content">
      <div className="content-wrap">
        <div className="container clearfix">
          <div className="col-md-12 col-lg-8 ">
            <div className="row clearfix">
              <div className="mb-3 mt-5">
                <h3>Oral Presentation Guideline</h3>
                <ol className="ol-size ml-4 mb-3">
                  <li>
                    Oral presentation schedule will be published in this
                    website.
                  </li>
                  <li>
                    The presentation file must be uploaded in the submission
                    system via email scientific.apastb2021@gmail.com by the
                    latest of March 9th, 2021.
                  </li>
                  <li>
                    The presentation session will be held in panel format led by
                    a moderator.
                  </li>
                  <li>
                    Each presenter has 7 minutes of pre-recorded presentation,
                    followed by 5 minutes of live discussion.
                  </li>
                  <li>
                    There will be 10 minutes break in every 5 presentation.
                  </li>
                  <li>
                    All presentation and discussion must be conducted in
                    English.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row clearfix">
              <div className="mb-3 mt-5">
                <h3>Poster Presentation Guideline</h3>
                <ol className="ol-size ml-4 mb-3">
                  <li>
                    Poster presentation schedule will be published in this
                    website.
                  </li>
                  <li>
                    The presenter should prepare the poster presentation session
                    using pre-recorded presentation.
                  </li>
                  <li>
                    Each presenter has 5 minutes of pre-recorded presentation.
                  </li>
                  <li>
                    Please submit a digital version of your poster as well as
                    your pre-recorded session via the APASTB submission system
                    through scientific.apastb2021@gmail.com by the latest of
                    March 9th, 2021.
                  </li>
                  <li>
                    We advise you to use the template poster file when creating
                    your poster. The template will be uploaded in this website.
                    Please be informed of further notice.
                  </li>
                  <li>
                    The maximum poster/banner dimensions is for Standard Screen
                    Viewing (4:3 ratio), designed for screen presentations at
                    virtual meetings.
                  </li>
                  <li>
                    Each poster must have a top label indicating the title of
                    the poster, the names of the authors and their affiliations.
                  </li>
                  <li>
                    Poster text should be large enough to be read from screen.
                  </li>
                  <li>
                    Keep illustrative material simple. Simple use of colour is
                    effective for adding emphasis.
                  </li>
                  <li>
                    Simple "Introduction" and "Conclusion" sections are usually
                    helpful. When feasible, use graphs for demonstrating
                    qualitative relationships, use tables for precise numerical
                    values.
                  </li>
                  <li>
                    Presenters are responsible for their poster presentation
                    session. Presenters who are not present during their session
                    are considered withdraw from the scientific poster
                    presentation.
                  </li>
                </ol>
              </div>
            </div>

            <div
              className="divider divider-center mb-4 mt-2"
              style={{ margin: "0 -15px" }}
            />

            <div className="row clearfix">
              <div className="mb-0">
                <div className="heading-block" style={{ paddingTop: "40px" }}>
                  <h5 className="font-2">Call for Paper Guideline</h5>
                </div>
              </div>
            </div>

            <div className="row clearfix">
              <div className="mb-0">
                <ol className="ol-size ml-4 mb-5">
                  <li>
                    Before submitting a full paper, you must register on the
                    APASTB 2021 website (https://apastb.bankjaringandansel.id)
                    and upload an abstract on the website system
                  </li>
                  <li>
                    Accepted abstracts will be offered to authors by the
                    scientific committee to submit papers in selected journals:
                    Journal of Biomimetics, Biomaterials and Biomedical
                    Engineering (JBBBE) or Malaysian Journal of Medicine and
                    Health Sciences (MJMHS). Then, the authors will receive an
                    approval notification by email along with information about
                    the account that will be created by the committee in the
                    editorial manager system. Subsequently, the corresponding
                    author must log in to their account and proceed the
                    submission process on the selected journal system. The
                    journal's website:
                    <ol type="a" style={{ margin: "1em 2em" }}>
                      <li>
                        JBBBE :{" "}
                        <a href="http://www.scientific.net">
                          http://www.scientific.net
                        </a>{" "}
                      </li>
                      <li>
                        MJMHS :{" "}
                        <a href="http://mc.manuscriptcentral.com/mjmhs">
                          http://mc.manuscriptcentral.com/mjmhs
                        </a>{" "}
                      </li>
                    </ol>
                  </li>
                  <li>
                    The manuscript should be authentic and have never been
                    published or being submitted for publication anywhere else
                  </li>
                  <li>Deadline of full text submission is 31 December 2020</li>
                  <li>
                    There is Article Processing Charge (APC) for accepted
                    manuscript.
                  </li>
                  <li>
                    Manuscript should meet the format Guidelines for each
                    journal below
                  </li>
                </ol>
              </div>
            </div>

            <div className="w-100" style={{ margin: "0 -15px" }}>
              <div className="clearfix" id="abstract">
                <div className="row mb-1">
                  <div className="col-md-6">
                    <h4 className="float-left vertical-center mb-3">
                      {" "}
                      Template for JBBBE{" "}
                    </h4>
                  </div>
                  <div className="col-md-6 mb-0">
                    <a
                      href="https://drive.google.com/file/d/1bLc7LrO8ad9D_386KJZAwtfA_eVJpiEM/view?usp=sharing"
                      target="blank"
                      className="button button-reg button-3d button-circle w-100 gradient-grey-orange mr-0 text-center mb-3 submit float-right"
                    >
                      Download Template
                    </a>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-md-6">
                    <h4 className="float-left vertical-center mb-3">
                      {" "}
                      Template for MMJMHS{" "}
                    </h4>
                  </div>
                  <div className="col-md-6 mb-0">
                    <a
                      href="https://drive.google.com/file/d/1dkxQTAV_YFAgQrCSYvyg4UY3_jYcm_iK/view?usp=sharing"
                      target="blank"
                      className="button button-reg button-3d button-circle w-100 gradient-grey-orange mr-0 text-center mb-3 submit float-right"
                    >
                      Download Template
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="divider divider-center mb-4 mt-2"
              style={{ margin: "0 -15px" }}
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default GuidelinesPage
